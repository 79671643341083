//#region ✨ scss import
import '/scss/app.scss';
//#endregion

//#region 💨 jquery global
import jquery from 'jquery';
window.$ = window.jQuery = jquery;
//#endregion

//#region ✳ vendors import
import 'bootstrap';
import anime from 'animejs';
import scrollMonitor from 'scrollmonitor';
import 'slick-carousel';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
import imagesLoaded from 'imagesloaded';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
//#endregion


$(function() {

//#region tooltips
$('[data-toggle="tooltip"]').tooltip();
//#endregion

//#region ➰ loader
anime({
  targets: '.loader__spinner polygon',
	opacity: {
		value: 1,
		duration: 800
	},
  delay: function(el, i) { return i * 50 },
	complete: function(anim) {
		imagesLoaded(document.querySelector('body'), function(instance) {
    	$('.loader').addClass('loader--loaded');
			$('.js-waiting').removeClass('js-waiting');
		});
  }
});
//#endregion

//#region navbar
const bodyScrollLockTarget = document.querySelector('.navbar-nav');
$('#navbar-main').on('show.bs.collapse', function () {
	disableBodyScroll(bodyScrollLockTarget);
});
$('#navbar-main').on('hidden.bs.collapse', function () {
	enableBodyScroll(bodyScrollLockTarget);
});
//#endregion

//#region common scroll reveals
$('.js-scroll').each(function() {
	let $jsScroll = $(this);
	let jsScrollWatcher = scrollMonitor.create($jsScroll);
	jsScrollWatcher.enterViewport(function() {
		$jsScroll.addClass('js-scroll--revealed');
	});
});
//#endregion

//#region home animations
//header
let headerHomeWatcher = scrollMonitor.create($('.header__image'));
headerHomeWatcher.enterViewport(function() {
	$('.header__image').addClass('header__image--revealed');
});
headerHomeWatcher.exitViewport(function() {
	$('.header__image').removeClass('header__image--revealed');
});
//boxes
$('.box').each(function() {
	let $box = $(this);
	let boxWatcher = scrollMonitor.create($box);
	boxWatcher.enterViewport(function() {
		$box.addClass('box--revealed');
	});
});
//#endregion


//#region partners carousel
$('.partners__carousel').slick({
	arrows: false,
	dots: false,
	autoplay: true,
	autoplaySpeed: 2000,
	slidesToShow: 5,
	responsive: [
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 3
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 2
			}
		}
	]
});
//#endregion

});